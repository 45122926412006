<template>
  <b-img
    center
    :width="width"
    :height="height"
    :src="appLogoImage"
  />

</template>

<script>
import {
  BImg,
} from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BImg,
  },
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>
